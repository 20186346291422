/**
 * @Description: 接口文档
 * @author: 蒋金津
 * @date: 2022/3/7 9:26
 */
 import { get, post } from "../plugins/request";

 /**
  *  mock接口
  */
 export const getUserInfo = (p) => get("/user/info", p);
 export const getMockMap = (p) => get("/mockMap", p);
 export const getGroupList = (p) => get("/group/list", p);
 export const getGroupScreenList = (p) => get("/group/screen/list", p);
 
 
 export const getLiftAlert = (p) => get("/lift/alert", p);
 
/**
*  公共接口
*/
  // 获取公司名称(别名)、公司类型
export const getCompanyName = (p) => get("/top/getCompanyName", p);
  
 /**
  *  使用单位大屏
  */
 // 电梯总量
 export const getCountLift = (p) => get("/elevator/elevatorNum", p);
 // 在线电梯
 export const getCountLiftOnline = (p) => get("/elevator/elevatorOnlineNum", p);
 // 正常运行
 export const getCountLiftRunning = (p) => get("/elevator/elevatorRunningNum", p);
 // 停止运行
 export const getCountLiftStopped = (p) => get("/elevator/elevatorStopNum", p);
 // 困人电梯
 export const getCountLiftTrapped= (p) => get("/elevator/elevatorTrappedNum", p);
 // 检修状态
 export const getCountLiftOverhaul = (p) => get("/elevator/elevatorAccessNum", p);
 
 // 电梯品牌占比
 export const getProportionLiftBrand = (p) => get("/elevator/brandProportion", p);
 // 近一年各种事件次数
 export const getLineEvent = (p) => get("/fault/faultEventTrendLine", p);
 // 当前异常电梯
 export const getFormLiftCurFault = (p) => get("/fault/current-abnormal-elevator-list", p);
 // 上月故障次数
 export const getCountLastMonthFault = (p) => get("/fault/last-month-fault-num", p);
 // 本月故障次数
 export const getCountThisMonthFault = (p) => get("/fault/this-month-fault-num", p);
 // 本月困人次数
 export const getCountFaultTrap = (p) => get("/fault/this-month-trapped-num", p);
 // 梯龄占比
 export const getProportionLiftAge = (p) => get("/elevator/ageProportion", p);
 // 近7日停梯时长TOP5
 export const getColumnLiftStoppedTime = (p) => get("/fault/stopElevatorBar", p);
 // 近30日急修次数Top5
 export const getColumnLiftRepairCount = (p) => get("/fault/repairNumBar", p);
 // 故障电梯TOP10
 // export const getColumnLiftFault = (p) => get("/fault/faultElevatorList", p);
 export const getColumnLiftFault = (p) => get("/fault/abnormalLiftTo10", p);
 // 本月已维保次数
 export const getCountMaintenance = (p) => get("/maintenance/maintenanceNum", p);
 // 本月待维保次数
 export const getCountPendingMaintenance = (p) => get("/maintenance/maintenancePendingNum", p);
 // 本月维保完成率
 export const getProportionMaintenance = (p) => get("/maintenance/maintenanceFinishRate", p);
 // 超期电梯
 export const getCountOverdue = (p) => get("/maintenance/maintenanceOverdueNum", p);
 // 超期电梯率
 export const getProportionOverdue = (p) => get("/maintenance/maintenanceOverdueRate", p);
 
 /**
  *  政府大屏
  */
 // 监管区域电梯 getCountLift
 // 当前正常运行 getCountLiftRunning
 // 当前故障电梯
 export const getCountLiftFault = (p) => get("/elevator/elevatorFaultNum", p);
 // 当前困人电梯 getCountLiftTrapped
 // 电梯台量分布
 export const getColumnLiftAreaCount = (p) => get("/area/liftArea", p);
 
 // 各维保公司电梯数量
 export const getColumnLiftOwnerCount = (p) => get("/elevator/companyLifts", p);
 // 平均响应时长
 export const getCalculateWoAverageResponseCost = (p) => get("/fault/avgResponseTime", p);
 // 平均救援时长
 export const getCalculateWoAverageRescueCost = (p) => get("/fault/avgRescueTime", p);
 // 解决困人事件
 export const getCalculateWoResolveTrappedCount = (p) => get("/maintenance/resolveTrappedNum", p);
 // 本月处理困人事件
 export const getCountThisMonthResolveTrappedCount = (p) => get("/maintenance/this-month-resolve-trapped-num", p);
 // 近半年处理困人事件数量
 export const getLineWoResolveTrappedCount = (p) => get("/maintenance/resolve-trapped-trend-bar", p);
 // 故障类型占比
 export const getProportionFaultType = (p) => get("/fault/faultProportion", p);
 // 近7日故障处理次数
 export const getColumnWoRepairCount = (p) => get("/maintenance/repairFaultNumBar", p);
 
 /**
  *  维保公司
  */
 // 电梯总量 getCountLift
 // 监测电梯数量
 export const getLiftMonitorCount = (p) => get("/elevator/elevatorMonitorNum", p);
 // 工单总量
 export const getWorkCount = (p) => get("/maintenance/workTotalNum", p);
 // 保养总量
 export const getMaintenanceCount = (p) => get("/maintenance/maintenanceTotalNum", p);
 // 急修总量
 export const getRepairsCount = (p) => get("/maintenance/repairsTotalNum", p);
 
 // 本月已维保次数 getCountMaintenance
 // 本月待维保次数 getCountPendingMaintenance
 // 下月应维保次数
 export const getCountMaintenanceNextMonth = (p) => get("/maintenance/next-month-maintenance-pending-num", p);
 // 维保人员人数
 export const getMaintenanceMemberCount = (p) => get("/maintenance/maintenancePeopleTotalNum", p);
 // 近一年维保量统计
 export const getMaintenanceLine = (p) => get("/maintenance/maintenanceNumLine", p);
 // 电梯维保次数统计
 export const getMaintenanceList = (p) => get("/maintenance/maintenanceNumList", p);
 
 // 合作客户总数及增长率
 export const getCustomerGrewNum = (p) => get("/project/customerGrewNum", p);
 // 项目总数及增长率
 export const getProjectGrewNum = (p) => get("/project/projectGrewNum", p);
 // 项目场所占比
 export const getProjectTypeProportion = (p) => get("/project/projectTypeProportion", p);
 // 合作客户区域分布
 export const getCustomerBar = (p) => get("/project/customerBar", p);
 // 重点客户电梯数
 export const getImportantCustomerBar = (p) => get("/project/importantCustomerBar", p);
 
 // 事件处置总数 getRepairsCount
 // 本月处置事件
 export const getRepairsCountMonth = (p) => get("/maintenance/repairsNum", p);
 // 处置困人事件 getCalculateWoResolveTrappedCount
 // 救援准点率
 export const getRescueOnTimeRate = (p) => get("/fault/rescueOnTimeRate", p);
 // 近一年事件处理趋势
 export const getWorkTrendLine = (p) => get("/maintenance/workTrendLine", p);
 // 各类事件占比
 export const getEventProportion = (p) => get("/project/eventProportion", p);
 
 /**
  *  崇州大屏
  */
 // 项目总数
 export const getProjectNum = (p) => get("/project/projectNum", p);
 // 电梯总量 getCountLift
 // 在线电梯 getCountLiftOnline
 // 离线电梯
 export const getCountLiftOffline = (p) => get("/elevator/offLineLiftNum", p);
 
 // 电梯品牌占比 getProportionLiftBrand
 // 梯龄占比 getProportionLiftAge
 // 电梯项目分布
 export const getProjectDistribution = (p) => get("/project/getListDistribution", p);
 // 近24小时异常电梯
 export const getAbnormalLiftOneDay = (p) => get("/fault/abnormalLiftTo24Hour", p);
 // 近7天异常统计
 export const getAbnormalLiftSevenDay = (p) => get("/fault/aAbnormalLiftTo7Day", p);
 // 困人电梯TOP5 （8 = 电梯困人）
 export const getAbnormalLiftEvent = (p) => get("/fault/abnormalLiftTop5", p);
 // 电瓶车进入轿厢电梯TOP5 （2 = 电瓶车进入轿厢）getAbnormalLiftEvent
 // 近30天故障趋势统计
 export const getAbnormalLiftMonth = (p) => get("/fault/abnormalLiftTo30Day", p);
 // 近30天故障代码统计
 export const getFaultCodeMonth = (p) => get("/fault/abnormalCodeBy30Day", p);
 // 故障电梯TOP10 getColumnLiftFault
 
 
 /**
  *  大屏5
  */
 // 合作客户
 export const cooperationCustomer = (p) => get("/top/cooperationCustomer", p);
 // 项目总数 getProjectNum
//  export const getProjectNum = (p) => get("/project/projectNum", p);
 // 电梯总量
 export const countLiftNum = (p) => get("/top/countLiftNum", p);
 // 监测电梯
 export const monitoringLift = (p) => get("/top/monitoringLift", p);
 
 // 保养工单 有维保计划
//  export const mainPlanCount = (p) => get("/workOrder/mainPlanCount", p);
 export const mainPlanCount = (p) => get("/workOrder/getMonthlyMaintenance", p);
 // 保养工单 无维保计划
 export const noMainPlanCount = (p) => get("/workOrder/noMainPlanCount", p);
 // 急修工单 全部急修工单
export const emergentCount = (p) => get("/workOrder/emergentCount", p);
 // 急修工单 困人报修
 export const trappedPeopleCount = (p) => get("/maintenance/trappedPeopleCount", p);
 // 本月维保完成率
 export const mainFinshRate = (p) => get("/workOrder/mainFinshRate", p);
 // 维保超期率
 export const mainOverdueRate = (p) => get("/workOrder/mainOverdueRate", p);
 
 // 即将年检
 export const getDueCount = (p) => get("/check/getDueCount", p);
 // 年检超期
 export const getCheckOverdueCount = (p) => get("/check/getCheckOverdueCount", p);
 // 年检完成率
 export const getCheckCompletionRate = (p) => get("/check/getCheckCompletionRate", p);
 
 // 已投保电梯数
 export const getCountInsureLift = (p) => get("/insure/getCountInsureLift", p);
 // 保险即将到期
 export const getInsureDueCount = (p) => get("/insure/getInsureDueCount", p);
 // 投保率
 export const getInsuranceRate = (p) => get("/insure/getInsuranceRate", p);
 
 // 电梯品牌占比
 export const brandProportion = (p) => get("/graph/brandProportion", p);
 // 项目场所占比
 export const projectTypeProportion = (p) => get("/graph/projectTypeProportion", p);
 // 近30天故障趋势统计
 export const abnormalLiftTo30Day = (p) => get("/graph/abnormalLiftTo30Day", p);
 // 电梯发生事件
 export const countOccursEvent = (p) => get("/screen/getAllData", p);
 // 故障类型占比
 export const countWorkOrderFault = (p) => get("/graph/countWorkOrderFault", p);
 // 电梯事件占比
 export const countFaultEvent = (p) => get("/graph/countFaultEvent", p);
 
 /**
  *  发布功能
  */
 export const getScreenStatus = (p) => get("/template/myTemplates", p);
 export const addScreenPublish = (p) => post("/template/publish", p);
 export const delScreenPublish = (p) => post("/template/unPublish", p);
 export const fromShare = (p) => get(`/share/screenId`, p);
 
 
 